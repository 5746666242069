<template>
  <div>
    <div v-if="!error">
      <b-link :to="{ name: 'home-customers' }">
        Customer List
      </b-link>
    </div>
    <div v-else>
      <portal to="body-top">
        <customer-name title="- Update contact" />
      </portal>
      <b-row>
        <b-col md="6">
          <div
            v-for="(item) in ['First Name', 'Email Address', 'Title']"
            :key="item"
            style="height: 70px"
          >
            <span class="inputLabel">{{ item }}</span>
            <b-skeleton-img
              height="40px"
              style="border-radius: 5px"
            />
          </div>
          <label class="contacts-label">{{
            $t('Preferred contact method')
          }}</label>
          <div
            v-for="(item) in ['Email', 'Phone call']"
            :key="item"
            style="height: 30px; display: flex; margin-bottom: 10px"
          >
            <span class="mr-4">{{ item }}</span>
            <b-skeleton-img
              width="20px"
              height="20px"
              style="border-radius: 5px"
            />
          </div>
          <div
            v-for="(item) in ['Date of Birth']"
            :key="item"
            style="height: 70px"
          >
            <span class="">{{ item }}</span>
            <b-skeleton-img
              height="40px"
              style="border-radius: 5px"
            />
          </div>
        </b-col>
        <b-col md="6">
          <div
            v-for="(item) in ['Last Name', 'Phone Number', 'Additional Phone Number ']"
            :key="item"
            style="height: 70px"
          >
            <span class="inputLabel">{{ item }}</span>
            <b-skeleton-img
              height="40px"
              style="border-radius: 5px"
            />
          </div>
          <label class="contacts-label">{{ $t('Contact Type') }}</label>

          <div
            v-for="(item) in ['Billing', 'Shipping', 'Account Holder']"
            :key="item"
            style="
              height: 30px;
              display: flex;
              align-item: center;
              margin-bottom: 10px;
            "
          >
            <span class="mr-4">{{ item }}</span>
            <b-skeleton-img
              width="20px"
              height="20px"
              style="border-radius: 5px"
            />
          </div>
          <label class="contacts-label">{{ $t('Attachments') }}</label>
          <div
            v-for="(item) in ['']"
            :key="item"
            style="height: 150px"
          >
            <b-skeleton-img
              height="130px"
              style="border-radius: 5px"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow, BSkeletonImg, BCol, BLink,
} from 'bootstrap-vue'
import CustomerName from '../../../components/CustomerName.vue'

export default {
  name: 'UpdateSkeleton',
  components: {
    BCol,
    BLink,
    BRow,
    BSkeletonImg,
    CustomerName,
  },
  props: {
    error: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
}
</script>
