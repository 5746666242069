<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <portal to="body-top">
      <customer-name title="- Update contact" />
    </portal>
    <contact-form
      v-if="contact.id"
      ref="contact-form"
      :is-edit="isEdit"
      :phone-options="phoneOptions"
    />
    <div v-else>
      <update-skeleton
        v-if="isLoading"
        :error="error"
      />
      <div v-else-if="notFound">
        <component-not-found
          title="Contacts List"
          :button-link="{name: 'home-customers-contacts-list'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, VBTooltip } from 'bootstrap-vue'
import store from '@/store'
import customerConfig from '../../../customConfig'
import CustomerName from '../../../components/CustomerName.vue'
// eslint-disable-next-line import/extensions
import UpdateSkeleton from '../skeletons/updateSkeleton.vue'
import config from '../contactsConfig'
import ContactForm from '../components/ContactForm.vue'
// eslint-disable-next-line import/extensions

export default {
  name: 'ContactsUpdate',
  components: {
    BCol,
    BRow,
    ContactForm,
    CustomerName,
    UpdateSkeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      error: {},
      datePattern: null,
      birthdayInputPlaceholder: null,
      birthday: '',
      notFound: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    contact() {
      return this.$store.state[this.MODULE_NAME].contactsForm
    },
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_FORM`, {})
    this.fetchData()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['contact-form']) {
      this.$refs['contact-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  methods: {
    isEditAble() {
      // eslint-disable-next-line no-return-assign
      this.isEdit = !this.isEdit
    },
    async fetchData() {
      this.isLoading = true

      await this.$store.dispatch(`${this.MODULE_NAME_ORGANIZATION}/get`).then(res => {
        const { data } = res.data
        this.datePattern = data?.date_format.label.split('/').map(item => (item[0] === 'y' ? item[0].toUpperCase() : item[0].toLowerCase()))
        this.birthdayInputPlaceholder = data?.date_format.label
      })

      await this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.contact_id)
        .then(res => {
          const { data } = res.data

          const contact = this.mappingFields(
            Object.keys(this.createFields),
            data,
            {
              id: data.id,
              attachments: data.attachments,
              customer_id: this.$route.params.id,
              phone_one: data.phone_one,
              phone_two: data.phone_two,
              phone_three: data.phone_three,
              phone_four: data.phone_four,

              phone_one_object: data.phone_one_object,
              phone_two_object: data.phone_two_object,
              phone_three_object: data.phone_three_object,
              phone_four_object: data.phone_four_object,

              phone_one_ext_val: data.phone_one_object ? data.phone_one_object.phone_ext_val ?? null : null,
              phone_two_ext_val: data.phone_two_object ? data.phone_two_object.phone_ext_val ?? null : null,
              phone_three_ext_val: data.phone_three_object ? data.phone_three_object.phone_ext_val ?? null : null,
              phone_four_ext_val: data.phone_four_object ? data.phone_four_object.phone_ext_val ?? null : null,

              phone_one_type: data.phone_one_object ? data.phone_one_object.phone_type_val ? this.phoneOptions.filter(item => item.label === data.phone_one_object.phone_type_val)[0] ?? null : null : null,
              phone_two_type: data.phone_two_object ? data.phone_two_object.phone_type_val ? this.phoneOptions.filter(item => item.label === data.phone_two_object.phone_type_val)[0] ?? null : null : null,
              phone_three_type: data.phone_three_object ? data.phone_three_object.phone_type_val ? this.phoneOptions.filter(item => item.label === data.phone_three_object.phone_type_val)[0] ?? null : null : null,
              phone_four_type: data.phone_four_object ? data.phone_four_object.phone_type_val ? this.phoneOptions.filter(item => item.label === data.phone_four_object.phone_type_val)[0] ?? null : null : null,

              // cell_no: data.cell_no,
              // phone_no: data.phone_no,
              // cell_no_object: JSON.parse(data.cell_no_object),
              // phone_no_object: JSON.parse(data.phone_no_object),
              preferred_contact_method: data.preferred_contact_method,
              // contact_types: data.contact_types.map(contactType => contactType.type),
              datePattern: this.datePattern,
              is_billing: data.is_billing,
              is_shipping: data.is_shipping,
              can_place_orders: data.can_place_orders,
              is_account_holder: data.is_account_holder,
              birthdayInputPlaceholder: this.birthdayInputPlaceholder,
              birthday: this.birthday,
            },
          )
          if (data.birthday) {
            const date = new Date(data.birthday)
            const dateArray = []

            contact.datePattern.forEach(pattern => {
              if (pattern === 'Y') {
                dateArray.push(date.getFullYear())
              }
              if (pattern === 'd') {
                dateArray.push(date.getDate())
              }
              if (pattern === 'm') {
                dateArray.push(date.getMonth() + 1)
              }
            })
            contact.birthday = dateArray.map(x => x.toString().padStart(2, '0')).join('/')
            // contact.birthday = [date.getMonth() + 1, date.getDate(), date.getFullYear()].map(x => x.toString().padStart(2, '0')).join('-')
          }

          // eslint-disable-next-line no-plusplus
          // Mapping Contact cell_no & phone_no
          for (let i = 0; i < this.phoneNumberFieldsJSONName.length; i++) {
            if (contact[this.phoneNumberFieldsJSONName[i].JSONName]) {
              contact[this.phoneNumberFieldsJSONName[i].name] = contact[this.phoneNumberFieldsJSONName[i].JSONName].formatNational
              contact[`${this.phoneNumberFieldsJSONName[i].name}_ext_val`] = contact[this.phoneNumberFieldsJSONName[i].JSONName][`${this.phoneNumberFieldsJSONName[i].name}_ext_val`]
              delete contact[this.phoneNumberFieldsJSONName[i].JSONName][`${this.phoneNumberFieldsJSONName[i].name}_ext_val`]
              delete contact[this.phoneNumberFieldsJSONName[i].JSONName].phoneNumber
            }
          }

          this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_FORM`, contact)
          this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_CONTACT_FORM_CLONE`, contact)
        }).catch(err => {
          this.error = err.response?.data
          this.notFound = true
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  setup() {
    const MODULE_NAME = 'contacts'
    const MODULE_NAME_CLONE = 'cloneData'
    const { setObjectOfPhoneMusk, preferredContactMethod } = customerConfig()
    const MODULE_NAME_ORGANIZATION = 'organization'

    const {
      createFields,
      phoneNumberFields,
      phoneNumberFieldsJSONName,
      phoneOptions,
    } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      createFields,
      phoneNumberFields,
      setObjectOfPhoneMusk,
      phoneNumberFieldsJSONName,
      preferredContactMethod,
      MODULE_NAME_ORGANIZATION,
      phoneOptions,
    }
  },
}
</script>
